import { ref } from 'vue';
import { defineStore } from 'pinia';
import objectPath from 'object-path';
import type LayoutConfigTypes from '@/types/interfaces/theme/metronic/config/LayoutConfigTypes';
import layoutConfig from '@/types/interfaces/theme/metronic/config/DefaultLayoutConfig';

export const LS_CONFIG_NAME_KEY = 'config_' + import.meta.env.VITE_APP_DEMO;

export const useConfigStore = defineStore('config', () => {
  const config = ref<LayoutConfigTypes>(layoutConfig);
  const initial = ref<LayoutConfigTypes>(layoutConfig);
  const pageTitle = ref<string>('Home');
  const breadcrumb = ref<string[]>([]);

  function getLayoutConfig(path: string, defaultValue?: string) {
    return objectPath.get(config.value, path, defaultValue);
  }

  function getPageTitle() {
    return pageTitle;
  }

  function getBreadcrumb() {
    return breadcrumb;
  }

  function setLayoutConfigProperty(property: string, value: any) {
    objectPath.set(config.value, property, value);
    localStorage.setItem(LS_CONFIG_NAME_KEY, JSON.stringify(config.value));
  }

  function setPageTitle(title: string) {
    pageTitle.value = title;
    useHead({
      title: 'ASUFIN - ' + title,
    });
  }

  function setBreadcrumb(data: string[]) {
    breadcrumb.value = data;
  }

  function resetLayoutConfig() {
    config.value = Object.assign({}, initial.value);
  }

  function overrideLayoutConfig() {
    config.value = initial.value = Object.assign(
      {},
      initial.value,
      JSON.parse(window.localStorage.getItem(LS_CONFIG_NAME_KEY) || '{}')
    );
  }

  function setLayoutConfig(layoutConfig: LayoutConfigTypes) {
    config.value = layoutConfig;
  }

  return {
    config,
    getLayoutConfig,
    getPageTitle,
    getBreadcrumb,
    setLayoutConfigProperty,
    setPageTitle,
    setBreadcrumb,
    resetLayoutConfig,
    overrideLayoutConfig,
    setLayoutConfig
  };
});
