import type LayoutConfigTypes from '@/types/interfaces/theme/metronic/config/LayoutConfigTypes';

const config: LayoutConfigTypes = {
  general: {
    mode: 'light',
    primaryColor: '#50CD89',
    pageWidth: 'default',
    layout: 'dark-sidebar',
    iconsType: 'duotone'
  },
  header: {
    display: true,
    default: {
      container: 'fluid',
      fixed: {
        desktop: true,
        mobile: true
      },
      menu: {
        display: true,
        iconType: 'bootstrap'
      }
    }
  },
  sidebar: {
    display: true,
    default: {
      minimize: {
        desktop: {
          enabled: true,
          default: false,
          hoverable: true
        }
      },
      menu: {
        iconType: 'bootstrap'
      }
    }
  },
  toolbar: {
    display: true,
    container: 'fluid',
    fixed: {
      desktop: false,
      mobile: false
    }
  },
  pageTitle: {
    display: true,
    breadcrumb: true,
    direction: 'column'
  },
  content: {
    container: 'fluid'
  },
  footer: {
    display: true,
    container: 'fluid',
    fixed: {
      desktop: true,
      mobile: true
    }
  },
  illustrations: {
    set: 'sketchy-1'
  },
  scrolltop: {
    display: true
  }
};

export default config;
