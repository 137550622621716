import { default as edit_45_91hash_93HP90ikD5yrMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/admins/edit-[hash].vue?macro=true";
import { default as listVN6sgV89pQMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/admins/list.vue?macro=true";
import { default as view_45_91hash_93GNfvJWvk71Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/admins/view-[hash].vue?macro=true";
import { default as analytics_45dashboard5qcUDBmj90Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-dashboard.vue?macro=true";
import { default as analytics_45judicial_45precedentsAowJ9E8UGNMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-judicial-precedents.vue?macro=true";
import { default as analytics_45public_45documentsEVIOuI92pTMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-public-documents.vue?macro=true";
import { default as analytics_45users_45monthlyemZzFnHylAMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-users-monthly.vue?macro=true";
import { default as analytics_45users_45plans8gPyFF9h0cMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-users-plans.vue?macro=true";
import { default as analytics_45usersfwNGDJjP9nMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-users.vue?macro=true";
import { default as listlmaEwaXjgcMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/audiences/list.vue?macro=true";
import { default as view_45_91hash_93LDlLCxuXy8Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/audiences/view-[hash].vue?macro=true";
import { default as sign_45inFGeghzmOWgMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/auth/sign-in.vue?macro=true";
import { default as listtpKUa6wne4Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/banks/list.vue?macro=true";
import { default as view_45_91hash_937u3fvP0vFBMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/banks/view-[hash].vue?macro=true";
import { default as listoj5LvA9RF3Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/claims/list.vue?macro=true";
import { default as listYjdC6mvkggMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/companies/list.vue?macro=true";
import { default as view_45_91hash_93FLRL3t9NgNMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/companies/view-[hash].vue?macro=true";
import { default as listikG66gpVBkMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/court-types/list.vue?macro=true";
import { default as view_45_91hash_93Pa4uqdZHiPMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/court-types/view-[hash].vue?macro=true";
import { default as listtSKNwyEByKMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/courts/list.vue?macro=true";
import { default as view_45_91hash_93KbhQ7w1Us9Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/courts/view-[hash].vue?macro=true";
import { default as dashboardMjPgsfFyABMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/dashboard.vue?macro=true";
import { default as listsX7WOvaM8KMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/faqs/list.vue?macro=true";
import { default as view_45_91hash_93oRxClI6ZLyMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/faqs/view-[hash].vue?macro=true";
import { default as stripe_45balanceCBMT2MhYcyMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/financial/stripe-balance.vue?macro=true";
import { default as indexT3tpCdY2AzMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/index.vue?macro=true";
import { default as listLSPnf9SYL8Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judges/list.vue?macro=true";
import { default as view_45_91hash_93zgk9ZiozjJMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judges/view-[hash].vue?macro=true";
import { default as list4mxXrpSKJ9Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedent-affected-type/list.vue?macro=true";
import { default as view_45_91hash_93cQctCaiQfCMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedent-affected-type/view-[hash].vue?macro=true";
import { default as listJf40hfFa42Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/list.vue?macro=true";
import { default as listEXM13sw8coMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/products/list.vue?macro=true";
import { default as view_45_91hash_93ohJouct5k6Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/products/view-[hash].vue?macro=true";
import { default as view_45_91hash_93ZtgDAOcj9xMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/view-[hash].vue?macro=true";
import { default as listVd6WUscBNLMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/lawyers/list.vue?macro=true";
import { default as view_45_91hash_93KUwYfZSoAwMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/lawyers/view-[hash].vue?macro=true";
import { default as listbcImGgARfzMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/news/list.vue?macro=true";
import { default as view_45_91hash_93E9lH5X9xbKMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/news/view-[hash].vue?macro=true";
import { default as profileU6ocwiXBgRMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/profile.vue?macro=true";
import { default as list1ViVGgTMCxMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents-types/list.vue?macro=true";
import { default as view_45_91hash_93O4YPxkSrFMMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents-types/view-[hash].vue?macro=true";
import { default as listKpPEgNfjRAMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/list.vue?macro=true";
import { default as listk9WA85P8p2Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/products/list.vue?macro=true";
import { default as view_45_91hash_931n8CwcR0xRMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/products/view-[hash].vue?macro=true";
import { default as view_45_91hash_93VvPouzyRVeMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/view-[hash].vue?macro=true";
import { default as edit_45_91hash_93rnBDrxDUXDMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/roles/edit-[hash].vue?macro=true";
import { default as listzErwCQDdBJMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/roles/list.vue?macro=true";
import { default as view_45_91hash_93OJfRcTxElgMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/roles/view-[hash].vue?macro=true";
import { default as listhEQWDFUFKaMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/tickets/list.vue?macro=true";
import { default as listnz9HcCCqTcMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/list.vue?macro=true";
import { default as listbKLvXk3tekMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/products/list.vue?macro=true";
import { default as view_45_91hash_93MGzgQsceEsMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/products/view-[hash].vue?macro=true";
import { default as view_45_91hash_93MmcYkVfNlTMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/view-[hash].vue?macro=true";
export default [
  {
    name: edit_45_91hash_93HP90ikD5yrMeta?.name ?? "admins-edit-hash",
    path: edit_45_91hash_93HP90ikD5yrMeta?.path ?? "/admins/edit-:hash()",
    meta: edit_45_91hash_93HP90ikD5yrMeta || {},
    alias: edit_45_91hash_93HP90ikD5yrMeta?.alias || [],
    redirect: edit_45_91hash_93HP90ikD5yrMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/admins/edit-[hash].vue").then(m => m.default || m)
  },
  {
    name: listVN6sgV89pQMeta?.name ?? "admins-list",
    path: listVN6sgV89pQMeta?.path ?? "/admins/list",
    meta: listVN6sgV89pQMeta || {},
    alias: listVN6sgV89pQMeta?.alias || [],
    redirect: listVN6sgV89pQMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/admins/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93GNfvJWvk71Meta?.name ?? "admins-view-hash",
    path: view_45_91hash_93GNfvJWvk71Meta?.path ?? "/admins/view-:hash()",
    meta: view_45_91hash_93GNfvJWvk71Meta || {},
    alias: view_45_91hash_93GNfvJWvk71Meta?.alias || [],
    redirect: view_45_91hash_93GNfvJWvk71Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/admins/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: analytics_45dashboard5qcUDBmj90Meta?.name ?? "analytics-analytics-dashboard",
    path: analytics_45dashboard5qcUDBmj90Meta?.path ?? "/analytics/analytics-dashboard",
    meta: analytics_45dashboard5qcUDBmj90Meta || {},
    alias: analytics_45dashboard5qcUDBmj90Meta?.alias || [],
    redirect: analytics_45dashboard5qcUDBmj90Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-dashboard.vue").then(m => m.default || m)
  },
  {
    name: analytics_45judicial_45precedentsAowJ9E8UGNMeta?.name ?? "analytics-analytics-judicial-precedents",
    path: analytics_45judicial_45precedentsAowJ9E8UGNMeta?.path ?? "/analytics/analytics-judicial-precedents",
    meta: analytics_45judicial_45precedentsAowJ9E8UGNMeta || {},
    alias: analytics_45judicial_45precedentsAowJ9E8UGNMeta?.alias || [],
    redirect: analytics_45judicial_45precedentsAowJ9E8UGNMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-judicial-precedents.vue").then(m => m.default || m)
  },
  {
    name: analytics_45public_45documentsEVIOuI92pTMeta?.name ?? "analytics-analytics-public-documents",
    path: analytics_45public_45documentsEVIOuI92pTMeta?.path ?? "/analytics/analytics-public-documents",
    meta: analytics_45public_45documentsEVIOuI92pTMeta || {},
    alias: analytics_45public_45documentsEVIOuI92pTMeta?.alias || [],
    redirect: analytics_45public_45documentsEVIOuI92pTMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-public-documents.vue").then(m => m.default || m)
  },
  {
    name: analytics_45users_45monthlyemZzFnHylAMeta?.name ?? "analytics-analytics-users-monthly",
    path: analytics_45users_45monthlyemZzFnHylAMeta?.path ?? "/analytics/analytics-users-monthly",
    meta: analytics_45users_45monthlyemZzFnHylAMeta || {},
    alias: analytics_45users_45monthlyemZzFnHylAMeta?.alias || [],
    redirect: analytics_45users_45monthlyemZzFnHylAMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-users-monthly.vue").then(m => m.default || m)
  },
  {
    name: analytics_45users_45plans8gPyFF9h0cMeta?.name ?? "analytics-analytics-users-plans",
    path: analytics_45users_45plans8gPyFF9h0cMeta?.path ?? "/analytics/analytics-users-plans",
    meta: analytics_45users_45plans8gPyFF9h0cMeta || {},
    alias: analytics_45users_45plans8gPyFF9h0cMeta?.alias || [],
    redirect: analytics_45users_45plans8gPyFF9h0cMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-users-plans.vue").then(m => m.default || m)
  },
  {
    name: analytics_45usersfwNGDJjP9nMeta?.name ?? "analytics-analytics-users",
    path: analytics_45usersfwNGDJjP9nMeta?.path ?? "/analytics/analytics-users",
    meta: analytics_45usersfwNGDJjP9nMeta || {},
    alias: analytics_45usersfwNGDJjP9nMeta?.alias || [],
    redirect: analytics_45usersfwNGDJjP9nMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/analytics/analytics-users.vue").then(m => m.default || m)
  },
  {
    name: listlmaEwaXjgcMeta?.name ?? "audiences-list",
    path: listlmaEwaXjgcMeta?.path ?? "/audiences/list",
    meta: listlmaEwaXjgcMeta || {},
    alias: listlmaEwaXjgcMeta?.alias || [],
    redirect: listlmaEwaXjgcMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/audiences/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93LDlLCxuXy8Meta?.name ?? "audiences-view-hash",
    path: view_45_91hash_93LDlLCxuXy8Meta?.path ?? "/audiences/view-:hash()",
    meta: view_45_91hash_93LDlLCxuXy8Meta || {},
    alias: view_45_91hash_93LDlLCxuXy8Meta?.alias || [],
    redirect: view_45_91hash_93LDlLCxuXy8Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/audiences/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: sign_45inFGeghzmOWgMeta?.name ?? "auth-sign-in",
    path: sign_45inFGeghzmOWgMeta?.path ?? "/auth/sign-in",
    meta: sign_45inFGeghzmOWgMeta || {},
    alias: sign_45inFGeghzmOWgMeta?.alias || [],
    redirect: sign_45inFGeghzmOWgMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: listtpKUa6wne4Meta?.name ?? "banks-list",
    path: listtpKUa6wne4Meta?.path ?? "/banks/list",
    meta: listtpKUa6wne4Meta || {},
    alias: listtpKUa6wne4Meta?.alias || [],
    redirect: listtpKUa6wne4Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/banks/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_937u3fvP0vFBMeta?.name ?? "banks-view-hash",
    path: view_45_91hash_937u3fvP0vFBMeta?.path ?? "/banks/view-:hash()",
    meta: view_45_91hash_937u3fvP0vFBMeta || {},
    alias: view_45_91hash_937u3fvP0vFBMeta?.alias || [],
    redirect: view_45_91hash_937u3fvP0vFBMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/banks/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listoj5LvA9RF3Meta?.name ?? "claims-list",
    path: listoj5LvA9RF3Meta?.path ?? "/claims/list",
    meta: listoj5LvA9RF3Meta || {},
    alias: listoj5LvA9RF3Meta?.alias || [],
    redirect: listoj5LvA9RF3Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/claims/list.vue").then(m => m.default || m)
  },
  {
    name: listYjdC6mvkggMeta?.name ?? "companies-list",
    path: listYjdC6mvkggMeta?.path ?? "/companies/list",
    meta: listYjdC6mvkggMeta || {},
    alias: listYjdC6mvkggMeta?.alias || [],
    redirect: listYjdC6mvkggMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/companies/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93FLRL3t9NgNMeta?.name ?? "companies-view-hash",
    path: view_45_91hash_93FLRL3t9NgNMeta?.path ?? "/companies/view-:hash()",
    meta: view_45_91hash_93FLRL3t9NgNMeta || {},
    alias: view_45_91hash_93FLRL3t9NgNMeta?.alias || [],
    redirect: view_45_91hash_93FLRL3t9NgNMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/companies/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listikG66gpVBkMeta?.name ?? "court-types-list",
    path: listikG66gpVBkMeta?.path ?? "/court-types/list",
    meta: listikG66gpVBkMeta || {},
    alias: listikG66gpVBkMeta?.alias || [],
    redirect: listikG66gpVBkMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/court-types/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93Pa4uqdZHiPMeta?.name ?? "court-types-view-hash",
    path: view_45_91hash_93Pa4uqdZHiPMeta?.path ?? "/court-types/view-:hash()",
    meta: view_45_91hash_93Pa4uqdZHiPMeta || {},
    alias: view_45_91hash_93Pa4uqdZHiPMeta?.alias || [],
    redirect: view_45_91hash_93Pa4uqdZHiPMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/court-types/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listtSKNwyEByKMeta?.name ?? "courts-list",
    path: listtSKNwyEByKMeta?.path ?? "/courts/list",
    meta: listtSKNwyEByKMeta || {},
    alias: listtSKNwyEByKMeta?.alias || [],
    redirect: listtSKNwyEByKMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/courts/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93KbhQ7w1Us9Meta?.name ?? "courts-view-hash",
    path: view_45_91hash_93KbhQ7w1Us9Meta?.path ?? "/courts/view-:hash()",
    meta: view_45_91hash_93KbhQ7w1Us9Meta || {},
    alias: view_45_91hash_93KbhQ7w1Us9Meta?.alias || [],
    redirect: view_45_91hash_93KbhQ7w1Us9Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/courts/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: dashboardMjPgsfFyABMeta?.name ?? "dashboard",
    path: dashboardMjPgsfFyABMeta?.path ?? "/dashboard",
    meta: dashboardMjPgsfFyABMeta || {},
    alias: dashboardMjPgsfFyABMeta?.alias || [],
    redirect: dashboardMjPgsfFyABMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: listsX7WOvaM8KMeta?.name ?? "faqs-list",
    path: listsX7WOvaM8KMeta?.path ?? "/faqs/list",
    meta: listsX7WOvaM8KMeta || {},
    alias: listsX7WOvaM8KMeta?.alias || [],
    redirect: listsX7WOvaM8KMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/faqs/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93oRxClI6ZLyMeta?.name ?? "faqs-view-hash",
    path: view_45_91hash_93oRxClI6ZLyMeta?.path ?? "/faqs/view-:hash()",
    meta: view_45_91hash_93oRxClI6ZLyMeta || {},
    alias: view_45_91hash_93oRxClI6ZLyMeta?.alias || [],
    redirect: view_45_91hash_93oRxClI6ZLyMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/faqs/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: stripe_45balanceCBMT2MhYcyMeta?.name ?? "financial-stripe-balance",
    path: stripe_45balanceCBMT2MhYcyMeta?.path ?? "/financial/stripe-balance",
    meta: stripe_45balanceCBMT2MhYcyMeta || {},
    alias: stripe_45balanceCBMT2MhYcyMeta?.alias || [],
    redirect: stripe_45balanceCBMT2MhYcyMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/financial/stripe-balance.vue").then(m => m.default || m)
  },
  {
    name: indexT3tpCdY2AzMeta?.name ?? "index",
    path: indexT3tpCdY2AzMeta?.path ?? "/",
    meta: indexT3tpCdY2AzMeta || {},
    alias: indexT3tpCdY2AzMeta?.alias || [],
    redirect: indexT3tpCdY2AzMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: listLSPnf9SYL8Meta?.name ?? "judges-list",
    path: listLSPnf9SYL8Meta?.path ?? "/judges/list",
    meta: listLSPnf9SYL8Meta || {},
    alias: listLSPnf9SYL8Meta?.alias || [],
    redirect: listLSPnf9SYL8Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judges/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93zgk9ZiozjJMeta?.name ?? "judges-view-hash",
    path: view_45_91hash_93zgk9ZiozjJMeta?.path ?? "/judges/view-:hash()",
    meta: view_45_91hash_93zgk9ZiozjJMeta || {},
    alias: view_45_91hash_93zgk9ZiozjJMeta?.alias || [],
    redirect: view_45_91hash_93zgk9ZiozjJMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judges/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: list4mxXrpSKJ9Meta?.name ?? "judicial-precedent-affected-type-list",
    path: list4mxXrpSKJ9Meta?.path ?? "/judicial-precedent-affected-type/list",
    meta: list4mxXrpSKJ9Meta || {},
    alias: list4mxXrpSKJ9Meta?.alias || [],
    redirect: list4mxXrpSKJ9Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedent-affected-type/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93cQctCaiQfCMeta?.name ?? "judicial-precedent-affected-type-view-hash",
    path: view_45_91hash_93cQctCaiQfCMeta?.path ?? "/judicial-precedent-affected-type/view-:hash()",
    meta: view_45_91hash_93cQctCaiQfCMeta || {},
    alias: view_45_91hash_93cQctCaiQfCMeta?.alias || [],
    redirect: view_45_91hash_93cQctCaiQfCMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedent-affected-type/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listJf40hfFa42Meta?.name ?? "judicial-precedents-list",
    path: listJf40hfFa42Meta?.path ?? "/judicial-precedents/list",
    meta: listJf40hfFa42Meta || {},
    alias: listJf40hfFa42Meta?.alias || [],
    redirect: listJf40hfFa42Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/list.vue").then(m => m.default || m)
  },
  {
    name: listEXM13sw8coMeta?.name ?? "judicial-precedents-products-list",
    path: listEXM13sw8coMeta?.path ?? "/judicial-precedents/products/list",
    meta: listEXM13sw8coMeta || {},
    alias: listEXM13sw8coMeta?.alias || [],
    redirect: listEXM13sw8coMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/products/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93ohJouct5k6Meta?.name ?? "judicial-precedents-products-view-hash",
    path: view_45_91hash_93ohJouct5k6Meta?.path ?? "/judicial-precedents/products/view-:hash()",
    meta: view_45_91hash_93ohJouct5k6Meta || {},
    alias: view_45_91hash_93ohJouct5k6Meta?.alias || [],
    redirect: view_45_91hash_93ohJouct5k6Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/products/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93ZtgDAOcj9xMeta?.name ?? "judicial-precedents-view-hash",
    path: view_45_91hash_93ZtgDAOcj9xMeta?.path ?? "/judicial-precedents/view-:hash()",
    meta: view_45_91hash_93ZtgDAOcj9xMeta || {},
    alias: view_45_91hash_93ZtgDAOcj9xMeta?.alias || [],
    redirect: view_45_91hash_93ZtgDAOcj9xMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/judicial-precedents/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listVd6WUscBNLMeta?.name ?? "lawyers-list",
    path: listVd6WUscBNLMeta?.path ?? "/lawyers/list",
    meta: listVd6WUscBNLMeta || {},
    alias: listVd6WUscBNLMeta?.alias || [],
    redirect: listVd6WUscBNLMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/lawyers/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93KUwYfZSoAwMeta?.name ?? "lawyers-view-hash",
    path: view_45_91hash_93KUwYfZSoAwMeta?.path ?? "/lawyers/view-:hash()",
    meta: view_45_91hash_93KUwYfZSoAwMeta || {},
    alias: view_45_91hash_93KUwYfZSoAwMeta?.alias || [],
    redirect: view_45_91hash_93KUwYfZSoAwMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/lawyers/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listbcImGgARfzMeta?.name ?? "news-list",
    path: listbcImGgARfzMeta?.path ?? "/news/list",
    meta: listbcImGgARfzMeta || {},
    alias: listbcImGgARfzMeta?.alias || [],
    redirect: listbcImGgARfzMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/news/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93E9lH5X9xbKMeta?.name ?? "news-view-hash",
    path: view_45_91hash_93E9lH5X9xbKMeta?.path ?? "/news/view-:hash()",
    meta: view_45_91hash_93E9lH5X9xbKMeta || {},
    alias: view_45_91hash_93E9lH5X9xbKMeta?.alias || [],
    redirect: view_45_91hash_93E9lH5X9xbKMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/news/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: profileU6ocwiXBgRMeta?.name ?? "profile",
    path: profileU6ocwiXBgRMeta?.path ?? "/profile",
    meta: profileU6ocwiXBgRMeta || {},
    alias: profileU6ocwiXBgRMeta?.alias || [],
    redirect: profileU6ocwiXBgRMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: list1ViVGgTMCxMeta?.name ?? "public-documents-types-list",
    path: list1ViVGgTMCxMeta?.path ?? "/public-documents-types/list",
    meta: list1ViVGgTMCxMeta || {},
    alias: list1ViVGgTMCxMeta?.alias || [],
    redirect: list1ViVGgTMCxMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents-types/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93O4YPxkSrFMMeta?.name ?? "public-documents-types-view-hash",
    path: view_45_91hash_93O4YPxkSrFMMeta?.path ?? "/public-documents-types/view-:hash()",
    meta: view_45_91hash_93O4YPxkSrFMMeta || {},
    alias: view_45_91hash_93O4YPxkSrFMMeta?.alias || [],
    redirect: view_45_91hash_93O4YPxkSrFMMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents-types/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listKpPEgNfjRAMeta?.name ?? "public-documents-list",
    path: listKpPEgNfjRAMeta?.path ?? "/public-documents/list",
    meta: listKpPEgNfjRAMeta || {},
    alias: listKpPEgNfjRAMeta?.alias || [],
    redirect: listKpPEgNfjRAMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/list.vue").then(m => m.default || m)
  },
  {
    name: listk9WA85P8p2Meta?.name ?? "public-documents-products-list",
    path: listk9WA85P8p2Meta?.path ?? "/public-documents/products/list",
    meta: listk9WA85P8p2Meta || {},
    alias: listk9WA85P8p2Meta?.alias || [],
    redirect: listk9WA85P8p2Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/products/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_931n8CwcR0xRMeta?.name ?? "public-documents-products-view-hash",
    path: view_45_91hash_931n8CwcR0xRMeta?.path ?? "/public-documents/products/view-:hash()",
    meta: view_45_91hash_931n8CwcR0xRMeta || {},
    alias: view_45_91hash_931n8CwcR0xRMeta?.alias || [],
    redirect: view_45_91hash_931n8CwcR0xRMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/products/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93VvPouzyRVeMeta?.name ?? "public-documents-view-hash",
    path: view_45_91hash_93VvPouzyRVeMeta?.path ?? "/public-documents/view-:hash()",
    meta: view_45_91hash_93VvPouzyRVeMeta || {},
    alias: view_45_91hash_93VvPouzyRVeMeta?.alias || [],
    redirect: view_45_91hash_93VvPouzyRVeMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/public-documents/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: edit_45_91hash_93rnBDrxDUXDMeta?.name ?? "roles-edit-hash",
    path: edit_45_91hash_93rnBDrxDUXDMeta?.path ?? "/roles/edit-:hash()",
    meta: edit_45_91hash_93rnBDrxDUXDMeta || {},
    alias: edit_45_91hash_93rnBDrxDUXDMeta?.alias || [],
    redirect: edit_45_91hash_93rnBDrxDUXDMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/roles/edit-[hash].vue").then(m => m.default || m)
  },
  {
    name: listzErwCQDdBJMeta?.name ?? "roles-list",
    path: listzErwCQDdBJMeta?.path ?? "/roles/list",
    meta: listzErwCQDdBJMeta || {},
    alias: listzErwCQDdBJMeta?.alias || [],
    redirect: listzErwCQDdBJMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/roles/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93OJfRcTxElgMeta?.name ?? "roles-view-hash",
    path: view_45_91hash_93OJfRcTxElgMeta?.path ?? "/roles/view-:hash()",
    meta: view_45_91hash_93OJfRcTxElgMeta || {},
    alias: view_45_91hash_93OJfRcTxElgMeta?.alias || [],
    redirect: view_45_91hash_93OJfRcTxElgMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/roles/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: listhEQWDFUFKaMeta?.name ?? "tickets-list",
    path: listhEQWDFUFKaMeta?.path ?? "/tickets/list",
    meta: listhEQWDFUFKaMeta || {},
    alias: listhEQWDFUFKaMeta?.alias || [],
    redirect: listhEQWDFUFKaMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/tickets/list.vue").then(m => m.default || m)
  },
  {
    name: listnz9HcCCqTcMeta?.name ?? "users-list",
    path: listnz9HcCCqTcMeta?.path ?? "/users/list",
    meta: listnz9HcCCqTcMeta || {},
    alias: listnz9HcCCqTcMeta?.alias || [],
    redirect: listnz9HcCCqTcMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/list.vue").then(m => m.default || m)
  },
  {
    name: listbKLvXk3tekMeta?.name ?? "users-products-list",
    path: listbKLvXk3tekMeta?.path ?? "/users/products/list",
    meta: listbKLvXk3tekMeta || {},
    alias: listbKLvXk3tekMeta?.alias || [],
    redirect: listbKLvXk3tekMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/products/list.vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93MGzgQsceEsMeta?.name ?? "users-products-view-hash",
    path: view_45_91hash_93MGzgQsceEsMeta?.path ?? "/users/products/view-:hash()",
    meta: view_45_91hash_93MGzgQsceEsMeta || {},
    alias: view_45_91hash_93MGzgQsceEsMeta?.alias || [],
    redirect: view_45_91hash_93MGzgQsceEsMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/products/view-[hash].vue").then(m => m.default || m)
  },
  {
    name: view_45_91hash_93MmcYkVfNlTMeta?.name ?? "users-view-hash",
    path: view_45_91hash_93MmcYkVfNlTMeta?.path ?? "/users/view-:hash()",
    meta: view_45_91hash_93MmcYkVfNlTMeta || {},
    alias: view_45_91hash_93MmcYkVfNlTMeta?.alias || [],
    redirect: view_45_91hash_93MmcYkVfNlTMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-app-v2/pages/users/view-[hash].vue").then(m => m.default || m)
  }
]